import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//import "nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "argon-dashboard-react/src/assets/scss/argon-dashboard-react.scss";
import React from "react";

import AuthLayout from "./layouts/Auth.js";
import AdminLayout from "./layouts/Admin.js";
import { connect } from 'react-redux'
import PrivateRoute from './components/PrivateRoute';
import {loadUser} from './actions/auth';

import './App.css';


class App extends React.Component{

  componentDidMount = () => {
    this.props.loadUser()
  }

  render(){
    return (
        <BrowserRouter>
          <Switch>
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <PrivateRoute path="/admin" component={AdminLayout} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>  
        </BrowserRouter>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loadUser: () => dispatch(loadUser())
})

export default connect(null, mapDispatchToProps)(App);
