/*!
=========================================================
* Argon Dashboard React - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import logo from '../logo.svg';

import { connect } from 'react-redux';
import { login as loginAction } from '../actions/auth';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";


import {
  Redirect
} from "react-router-dom";

import './Login.css';

class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {email:'', password:''};  
  }


  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }  

  handleSubmit = event => {
    event.preventDefault()
    this.props.login(this.state.email, this.state.password);
  }

  render() {
    if (this.props.isAuthenticated){
      return <Redirect
            to={{
              pathname: this.state.from,
            }} />
    } else{
    return (
      <>
          <Col lg="5" md="7"> 

            <Card className="bg-secondary shadow border-0">
              <div className="text-center mt-4">
                <img alt='letsstream logo' class='logo' src={logo}/>
              </div>
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Login with credentials</small>
                </div>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name='email' placeholder="Email" type="email" autoComplete="new-email" value={this.state.email} onChange={this.handleChange} />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name='password' placeholder="Password" type="password" autoComplete="new-password" value={this.state.password} onChange={this.handleChange}/>
                    </InputGroup>
                  </FormGroup>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="button" type="submit">
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <a
                  className="text-light"
                  href="/auth/forgot"
                >
                  <small>Forgot password?</small>
                </a>
              </Col>
              <Col className="text-right" xs="6">
                <a
                  className="text-light"
                  href="/auth/register"
                >
                  <small>Create new account</small>
                </a>
              </Col>
            </Row>
            </Col> 
        </>
      )
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login : (email, password) => { dispatch(loginAction(email, password)) }
  };
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);