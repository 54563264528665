/*!
=========================================================
* Argon Dashboard React - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

import { connect } from 'react-redux';
import { logout as LogoutAction } from '../actions/auth';

import {
  Redirect
} from "react-router-dom";


class Logout extends React.Component {
  constructor(props){
    super(props);
  }




  render() {
    if (this.props.isAuthenticated){
        this.props.logout()
        return <Redirect
                to={{
                pathname: "/",
                }} />
    } else{
        return ( <Redirect to={{pathname: "/auth/logout"}} />)
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout : () => { dispatch(LogoutAction()) }
  };
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);