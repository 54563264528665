import Login from './views/Login'
import Logout from './views/Logout'
import Register from './views/Register'
import Dashboard from './views/Dashboard'
import Video from './views/Video'
import Account from './views/Account'

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/account",
    name: "Social accounts",
    icon: "ni ni-tv-2 text-primary",
    component: Account,
    layout: "/admin"
  },
  {
    path: "/video",
    name: "Video",
    icon: "ni ni-tv-2 text-primary",
    component: Video,
    layout: "/admin"
  },  
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-tv-2 text-primary",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-tv-2 text-primary",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-tv-2 text-primary",
    component: Logout,
    layout: "/auth"
  }    
];
export default routes;