import React from "react";


class Account extends React.Component {
  render() {
    return (
      <>
      </>
    );
  }
}

export default Account;